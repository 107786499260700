<template>
<div class="dashboard-page">
  <div class="row">
    <div class="col-sm-12 d-flex justify-content-end">
      <button @click="openModal" class="btn btn-primary" type="button">
          <span>Download</span>
        </button>
    </div>
  </div>
  <!-- tiles: average info  -->
  <div class="row">
    <div class="col-sm-12">
      <vuestic-widget :loading="loading" class="tiles-widget">
        <div class="row justify-content-between">
          <!-- Total call duration -->
          <div class="custom-col">
            <div class="tile-box-total-call-duration text-left">
              <div class="tile-background-overlay">
                <i class="iconicstroke iconicstroke-clock"></i>
              </div>
              <div class="tile-header">Total Call Duration</div>
              <div class="tile-body">
                <span v-if="analytics.totalCallDuration.hour">{{analytics.totalCallDuration.hour}}<sub>hours</sub></span>
                {{analytics.totalCallDuration.min}}<sub>mins</sub> {{analytics.totalCallDuration.sec}}<sub>sec</sub>
              </div>
            </div>
          </div>
          <!-- average call duration -->
          <div class="custom-col">
            <div class="tile-box-average-call-duration text-left">
              <div class="tile-background-overlay">
                <i class="iconicstroke iconicstroke-clock"></i>
              </div>
              <div class="tile-header">Average Call Duration</div>
              <div class="tile-body">
                <span v-if="analytics.averageCallDuration.hour">{{analytics.averageCallDuration.hour}}<sub>hours</sub></span>
                {{analytics.averageCallDuration.min}}<sub>mins</sub> {{analytics.averageCallDuration.sec}}<sub>sec</sub>
              </div>
            </div>
          </div>
          <!-- average time to answer -->
          <div class="custom-col">
            <div class="tile-box-average-time-to-answer text-left">
              <div class="tile-background-overlay">
                <i class="fa fa-volume-control-phone transform-rotate-m45"></i>
              </div>
              <div class="tile-header">Average Time To Answer</div>
              <div class="tile-body">
                <span v-if="analytics.averageAnswerTime.hour">{{analytics.averageAnswerTime.hour}}<sub>hours</sub></span>
                {{analytics.averageAnswerTime.min}}<sub>mins</sub> {{analytics.averageAnswerTime.sec}}<sub>sec</sub>
              </div>
            </div>
          </div>
          <!-- total calls / connected calls -->
          <div class="custom-col">
            <div class="tile-box-calls text-left">
              <div class="tile-background-overlay">
                <i class="fa fa-phone"></i>
              </div>
              <div class="tile-header">Total Calls / Connected Calls</div>
              <div class="tile-body">
                {{analytics.countCalls}} <sub>/ {{analytics.countCallsConnected}}</sub> <strong>({{analytics.countCallsConnectedPercent}}<sup>%</sup>)</strong>
              </div>
            </div>
          </div>
          <!-- widget impression / opens -->
          <div class="custom-col">
            <div class="tile-box-impression text-left">
              <div class="tile-background-overlay">
                <i class="fa fa-bar-chart"></i>
              </div>
              <div class="tile-header">Widget Impression / Opens</div>
              <div class="tile-body">
                {{analytics.countConversion}} <sub>/ {{analytics.countConversionOpen}}</sub> <strong>({{analytics.countConversionOpenPercent}}<sup>%</sup>)</strong>
              </div>
            </div>
          </div>
        </div>
      </vuestic-widget>
    </div>
  </div>

  <!-- chart: calls -->
  <div class="row">
    <div class="col-sm-12">
      <vuestic-widget :loading="loading" class="chart-widget" headerText="Calls">
        <apexchart class="d-none-print" width="100%" height="400" type="line" :options="chart.calls.options" :series="chart.calls.series"></apexchart>
        <img id="chart-calls-img" class="d-print" style="width: 100%">
      </vuestic-widget>
    </div>
  </div>

  <div class="row">
    <!-- chart: time of day statistics -->
    <div class="col-sm-9 col-print">
      <vuestic-widget :loading="loading" class="chart-widget" headerText="Time of Day Statistics">
        <apexchart class="d-none-print"  width="100%" height="400" type="bar" :options="chart.timeOfDay.options" :series="chart.timeOfDay.series"></apexchart>
        <img id="chart-time-of-day-img" class="d-print" style="width: 100%" />
      </vuestic-widget>
    </div>

    <!-- list: time of day statistics -->
    <div class="col-sm-3 widget-flex-box col-print">
      <vuestic-widget :loading="loading" class="list-widget widget-flex-item" headerText="Call Status">
        <div class="analytics-list">
          <div v-for="(item, index) in analytics.list.callStatus"
            v-bind:key="index"
            class="analytics-list-element row"
          >
            <div class="col-sm-6 text-left">{{ item.title }}</div>
            <div class="col-sm-6 text-right">{{ item.value }}</div>
          </div>
        </div>
      </vuestic-widget>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12" v-if="false">
      <vuestic-widget :loading="loading" class="list-widget" headerText="Analytics">
        <div class="row">
          <!-- impressions -->
          <div class="col-sm-4 text-center">
            <div>Impressions</div>
            <div class="display-4 font-weight-bold">{{analytics.countConversion}}</div>
          </div>
          <!-- connections amount -->
          <div class="col-sm-4 text-center">
            <div>Connections</div>
            <div class="display-4 font-weight-bold">{{analytics.countCallsConnected}}</div>
          </div>
          <!-- connections percent -->
          <div class="col-sm-4 text-center">
            <div>Connections</div>
            <div class="display-4 font-weight-bold">{{analytics.countImpressionConnectedPercent}}<sup>%</sup></div>
          </div>
        </div>
      </vuestic-widget>
    </div>

    <div v-if="tableStats.length" class="list-call-stats col-md-12 d-print">
      <table class="table table-bordered">
        <tr>
          <th>
            Date
          </th>
          <th>
            Connected calls
          </th>
          <th>
            All calls
          </th>
        </tr>
        <tr v-for="(row, index) in tableStats" :key="index">
          <td>
            {{ row.label }}
          </td>
          <td>
            {{ row.connected }}
          </td>
          <td>
            {{ row.total }}
          </td>
        </tr>
      </table>
    </div>
  </div>

   <vuestic-modal
      :isOpen="isOpenModal"
      @cancel="closeModal"
      :cancelShown="false"
      :okShown="false"
    >
      <span slot="title">Download Report</span>
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-3">You can select type of report here</h6>
          <label class="field-label">Type</label>
          <multiselect
            class="filter-select d-inline-block"
            v-model="downloadType"
            :options="downloadTypeList"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Type"
            label="label"
            track-by="id"
            :preselect-first="true"
            :show-labels="false"
            :searchable="false"
            :allowEmpty="false"
          ></multiselect>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button class="btn btn-primary"  @click="downloadReport" style="min-width: 80px;">
            <span>Download</span>
          </button>
          <button type="button" class="btn btn-outline ml-2" @click="closeModal">Close</button>
        </div>
      </div>
    </vuestic-modal>

</div>

</template>

<script>
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    DateRangePicker,
  },
  props: {
    filter: {
      type: Object,
      default: {},
    }
  },
  data: function () {
    return {
      isOpenModal: false,
      downloadTypeList: [
        { id: 'all', label: 'All' },
        { id: 'connected', label: 'Connected' },
        { id: 'not_connected', label: 'Not Connected' }
      ],
      downloadType: null,
      loading: false,
      businessAvailabeInstall: null,
      businessTotalInstall: null,
      chart : {
        calls: {
          options: {
            chart: {
              id: 'chart-calls',
              toolbar: {
                show: false,
              },
            },
            stroke: {
              curve: 'straight',
              width: 2,
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 4,
            },
            colors: ['#3178c6', '#fe7058'],
            xaxis: {
              categories: []
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val ? val : ''
              },
              style: {
                fontSize: '18px',
                colors: ["#1a76d2"]
              }
            },
          },
          series: [
            {
              name: 'All Calls',
              data: []
            },
            {
              name: 'Connected Calls',
              data: []
            },
          ]
        },
        timeOfDay: {
          options: {
            chart: {
              id: 'chart-time-of-day',
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
              }
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val ? val : ''
              },
              style: {
                fontSize: '18px',
              }
            },
            colors: ['#3178c6', '#fe7058'],
            xaxis: {
              categories: ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12']
            },
          },
          series: [
            {
              name: 'Total Calls',
              data: [30, 40, 80, 50, 100, 60, 70, 91, 105, 140, 230, 250]
            },
            {
              name: 'Connected Calls',
              data: [20, 35, 35, 45, 45, 50, 70, 90, 100, 120, 190, 200]
            },
          ]
        }
      },

      analyticsRawData: {},
      analytics: {
        totalCallDuration : {
          hour: null,
          min: '00',
          sec: '00',
        },
        averageCallDuration : {
          hour: null,
          min: '00',
          sec: '00',
        },
        averageAnswerTime: {
          hour: null,
          min: '00',
          sec: '00',
        },
        countCalls: 0,
        countCallsConnected: 0,
        countCallsConnectedPercent: 0,
        countConversion: 0,
        countConversionOpen: 0,
        countConversionOpenPercent: 0,
        countConversionDesktop: 0,
        countCallsConnectedDesktop: 0,
        countCallsConnectedDesktopPercent: 0,
        countConversionMobile: 0,
        countCallsConnectedMobile: 0,
        countCallsConnectedMobilePercent: 0,
        countImpressionConnectedPercent: 0,
        list: {
          callStatus: {
            initiated: {title: 'Initiated', value: '00'},
            answered: {title: 'Answered', value: '00'},
            connected: {title: 'Connected', value: '00'},
            notConnected: {title: 'Not Connected', value: '00'},
          }
        },
      },
    }
  },
  
  computed: {
    tableStats() {
      let data = []

      for (let index in this.chart.calls.options.xaxis.categories) {
        if (this.chart.calls.series[0].data[index]) {
          data.push({
            label: this.chart.calls.options.xaxis.categories[index],
            total: this.chart.calls.series[0].data[index],
            connected: this.chart.calls.series[1].data[index],
          })
        }
      }
      return data
    },
  },

  mounted() {
    this.$store.dispatch('business/getInstallStats')
      .then(res => {
        this.businessTotalInstall = res.data.total_installs;
        this.businessAvailabeInstall = res.data.available_installs;
      })

    this.handleFilterUpdate();
  },
  
  methods: {
    openModal () {
      this.isOpenModal = true
    },
    closeModal () {
      this.isOpenModal = false
    },
    downloadReport () {
      this.isOpenModal = false
      const filter = {
        install: this.filter.install.value.id,
        agents: this.filter.agents.value.map(item => item.id),
        rangeDateStart: moment(this.filter.date.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        rangeDateEnd: moment(this.filter.date.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.loading = true
      const param = {
        type: 'call',
        download_type: this.downloadType.id,
        filter
      }
      this.$store.dispatch('business/downloadReportAnalytic', param).then((res) => {
        this.loading = false
        const blob = new Blob([res], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'analytics.xlsx'
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(e => {
        this.loading = false
      })
    },
    handleFilterUpdate() {
      const filter = {
        install: this.filter.install.value.id,
        agents: this.filter.agents.value.map(item => item.id),
        rangeDateStart: moment(this.filter.date.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        rangeDateEnd: moment(this.filter.date.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.loading = true
      const param = {
        type: 'call',
        filter
      }
      this.$store.dispatch('business/fetchAnalytic', param)
        .then((data) => {
          this.loading = false
          if(!data.success) return;
          this.analyticsRawData = data.data;

          this.updateAverageInfo();
          this.updateChartCalls();
          this.updateChartTimeOfDay();
          this.updateListCallStatus();
        }).catch(e => {
          this.loading = false
        })
    },

    getHMS(time) {
      const hours = Math.floor(time / (60 * 60));

      const divisorForMinutes = time % (60 * 60);
      const minutes = Math.floor(divisorForMinutes / 60);

      const divisorForSeconds = divisorForMinutes % 60;
      const seconds = Math.ceil(divisorForSeconds);

      return {
        hour: hours,
        min: (minutes < 10) ? '0' + minutes : minutes,
        sec: (seconds < 10) ? '0' + seconds : seconds
      };
    },

    updateAverageInfo() {
      //totalCallDuration
      this.analytics.totalCallDuration = this.getHMS(this.analyticsRawData.totalCallDuration);

      //averageCallDuration
      this.analytics.averageCallDuration = this.getHMS(this.analyticsRawData.averageCallDuration);

      //averageAnswerTime
      this.analytics.averageAnswerTime = this.getHMS(this.analyticsRawData.averageAnswerTime);

      //calls
      this.analytics.countCalls = this.analyticsRawData.countCalls;
      this.analytics.countCallsConnected = this.analyticsRawData.countCallsConnected;
      this.analytics.countCallsConnectedPercent = (this.analyticsRawData.countCalls && this.analyticsRawData.countCallsConnected) ? parseInt((this.analyticsRawData.countCallsConnected / this.analyticsRawData.countCalls) * 100) : 0;

      //conversions
      this.analytics.countConversion = this.analyticsRawData.countConversion;
      this.analytics.countConversionOpen = this.analyticsRawData.countConversionOpen;
      this.analytics.countConversionOpenPercent = (this.analyticsRawData.countConversion && this.analyticsRawData.countConversionOpen) ? parseInt((this.analyticsRawData.countConversionOpen / this.analyticsRawData.countConversion) * 100) : 0;

      this.analytics.countImpressionConnectedPercent = (this.analyticsRawData.countCalls && this.analyticsRawData.countCallsConnected) ? parseInt((this.analyticsRawData.countCallsConnected / this.analytics.countConversion) * 100) : 0;

      //conversions: desktop
      this.analytics.countConversionDesktop = this.analyticsRawData.countConversionDesktop;
      this.analytics.countCallsConnectedDesktop = this.analyticsRawData.countCallsConnectedDesktop;
      this.analytics.countCallsConnectedDesktopPercent = (this.analyticsRawData.countConversionDesktop && this.analyticsRawData.countCallsConnectedDesktop) ? parseInt((this.analyticsRawData.countCallsConnectedDesktop / this.analyticsRawData.countConversionDesktop) * 100) : 0;

      //conversions: mobile
      this.analytics.countConversionMobile = this.analyticsRawData.countConversionMobile;
      this.analytics.countCallsConnectedMobile = this.analyticsRawData.countCallsConnectedMobile;
      this.analytics.countCallsConnectedMobilePercent = (this.analyticsRawData.countConversionMobile && this.analyticsRawData.countCallsConnectedMobile) ? parseInt((this.analyticsRawData.countCallsConnectedMobile / this.analyticsRawData.countConversionMobile) * 100) : 0;
    },

    updateChartCalls() {
      //prepare
      const xaxisCategories = [];
      const yaxisCallsCount = [];
      const yaxisCallsConnectedCount = [];

      const rangeDateStart = moment(this.filter.date.dateRange.startDate).startOf('day');
      const rangeDateEnd = moment(this.filter.date.dateRange.endDate).endOf('day');
      const range = moment.range(rangeDateStart, rangeDateEnd);

      //collect
      for (let date of range.by('days')) {
        xaxisCategories.push(date.format('MMMM D'));
        let dateKey = date.format('YYYY-MM-DD');
        yaxisCallsCount.push((dateKey in this.analyticsRawData.listCountCallsByDate) ? this.analyticsRawData.listCountCallsByDate[dateKey] : 0);
        yaxisCallsConnectedCount.push((dateKey in this.analyticsRawData.listCountCallsConnectedByDate) ? this.analyticsRawData.listCountCallsConnectedByDate[dateKey] : 0);
      }

      //update
      this.chart.calls.options = {...this.chart.calls.options, ...{
        xaxis: { categories: xaxisCategories }
      }};
      this.chart.calls.series[0].data = yaxisCallsCount;
      this.chart.calls.series[1].data = yaxisCallsConnectedCount;
    },

    updateChartTimeOfDay() {
      //prepare
      const xaxisCategories = [];
      const yaxisCallsCount = [];
      const yaxisCallsConnectedCount = [];

      const rangeDateStart = moment().startOf('day');
      const rangeDateEnd = moment().endOf('day');
      const range = moment.range(rangeDateStart, rangeDateEnd);

      //collect (step: 2h)
      for (let hour of range.by('hours', { step: 2 })) {
        let nextHour = hour.clone().add(1, 'hours');
        xaxisCategories.push(hour.format('HH') +'-'+ (nextHour.format('HH') !== '00' ? nextHour.format('HH') : 24));

        //combine data for current hour and next hour
        let hourKey = hour.clone().utc().format('H');
        let hourCallsCount = (hourKey in this.analyticsRawData.listCountCallsByHour) ? this.analyticsRawData.listCountCallsByHour[hourKey] : 0;
        let hourCallsConnectedCount = (hourKey in this.analyticsRawData.listCountCallsConnectedByHour) ? this.analyticsRawData.listCountCallsConnectedByHour[hourKey] : 0;

        let nextHourKey = nextHour.clone().utc().format('H');
        let nextHourCallsCount = (nextHourKey in this.analyticsRawData.listCountCallsByHour) ? this.analyticsRawData.listCountCallsByHour[nextHourKey] : 0;
        let nextHourCallsConnectedCount = (nextHourKey in this.analyticsRawData.listCountCallsConnectedByHour) ? this.analyticsRawData.listCountCallsConnectedByHour[nextHourKey] : 0;

        //push data
        yaxisCallsCount.push(hourCallsCount + nextHourCallsCount);
        yaxisCallsConnectedCount.push(hourCallsConnectedCount + nextHourCallsConnectedCount);
      }

      //update
      this.chart.timeOfDay.options = {...this.chart.timeOfDay.options, ...{
        xaxis: { categories: xaxisCategories }
      }};
      this.chart.timeOfDay.series[0].data = yaxisCallsCount;
      this.chart.timeOfDay.series[1].data = yaxisCallsConnectedCount;
    },

    updateListCallStatus() {
      this.analytics.list.callStatus.initiated.value = this.analyticsRawData.countCalls;
      this.analytics.list.callStatus.answered.value = this.analyticsRawData.countCallsAnswered;
      this.analytics.list.callStatus.connected.value = this.analyticsRawData.countCallsConnected;
      this.analytics.list.callStatus.notConnected.value = this.analyticsRawData.countCallsNotConnected;
    }
  },
};
</script>

<style lang="scss" scoped>
  .table-bordered {
    border: 1px solid #ddd;
  }

  .table-bordered th,
  .table-bordered td {
      border: 1px solid #ddd !important;
      color: #4e5467;
  };
  .widget {
    &.chart-widget {
      .widget-body {
        height: 550px;
      }
    }

    .widget-header {
      color: #a6a6a6;
      border-bottom: 1px solid #a6a6a6;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .widget-flex-box {
    display: flex;
    .widget-flex-item {
      flex: auto;
    }
  }

  .analytics-list {
    .analytics-list-element {
      line-height: 45px;
    }
    .analytics-list-element:nth-child(even){
      background-color: #f2f2f2;
    }
  }

  sub, sup {
    font-size: 60%;
  }

  .tiles-widget {
    [class*="tile-box-"] {
      position: relative;
      color: white;
      background-color: #bdbeb9;
      height: 100%;
      padding: 24px 19px 19px 26px;

      .tile-background-overlay {
        pointer-events: none;
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;

        text-align: right;

        i {
          position: absolute;
          top: calc(50% - 0.5em);
          right: 20px;
          font-size: 80px;
          line-height: 80px;
          color: rgba(255, 253, 253, 0.3);
        }
      }

      .tile-header {
        font-size: 15px;
        font-weight: bold;
      }

      .tile-body {
        margin: 20px 0 0 0;
        font-size: 35px;
        font-weight: bold;
        line-height: 35px;

        sub {
          font-weight: bold;
          font-size: 0.45em;
          bottom: 0em;
        }
        strong {
          font-size: 1.4em;
          sup {
            font-weight: bold;
            top: -1.2em;
            font-size: .35em;
          }
        }
      }
    }
    @media (min-width: 576px) {
      .custom-col {
        width: 95%;
      }
    }
    @media (min-width: 768px) {
      .custom-col {
        width: 50%;
      }
    }
    @media (min-width: 992px) {
      .custom-col {
        width: 25%;
      }
    }
    @media (min-width: 1200px) {
      .custom-col {
        width: 19%;
      }
    }
    .tile-box-average-call-duration {
      background-color: #3178c6;
    }
    .tile-box-total-call-duration {
      background-color: #552f84;
    }
    .tile-box-average-time-to-answer{
      background-color: #0d9d58;
    }
    .tile-box-calls {
      background-color: #fe7058;
    }
    .tile-box-impression{
      background-color: #314a5e;
    }
  }

  @media (max-width: 1550px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 60px;
            line-height: 60px;
          }
        }

        .tile-header {
          font-size: 12px;
          font-weight: normal;
        }

        .tile-body {
          margin: 10px 0 0 0;
          font-size: 25px;
          line-height: 25px;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 60px;
            line-height: 60px;
          }
        }

        .tile-header {
          font-size: 11px;
          font-weight: normal;
        }

        .tile-body {
          margin: 10px 0 0 0;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 1210px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 50px;
            line-height: 50px;
          }
        }

        .tile-header {
          font-size: 10px;
          font-weight: normal;
        }

        .tile-body {
          margin: 10px 0 0 0;
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }

  @media (max-width: 1110px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 40px;
            line-height: 40px;
          }
        }

        .tile-header {
          font-size: 9px;
          font-weight: normal;
        }

        .tile-body {
          margin: 10px 0 0 0;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }

  @media (max-width: 1050px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 35px;
            line-height: 35px;
          }
        }

        .tile-header {
          font-size: 8px;
          font-weight: normal;
        }

        .tile-body {
          margin: 8px 0 0 0;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }


  .transform-rotate-m45 {
    transform: rotate(-45deg);
  }

  .filter-widget{
    .filter-title {
      font-size: 16px;
      font-weight: bold;
      padding-top: 12px;
    }

    .form-group {
      min-height: auto;
    }
  }

  .field-label {
    display: block;
    text-align: left;
    clear: both;
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
    top: -0.6rem;
    left: 0;
    margin-bottom: 3px;
  }

  .bus_inst_av{
    font-size: 1rem;
    color: #777;
  }

</style>

<style lang="scss">
  .vue-daterange-picker {
    display: block !important;

    //make range picker behave as adequate input
    .reportrange-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0.45rem 0.75rem;
      border: .0625rem solid #e7e7e7;
      height: inherit;
    }

    .daterangepicker {
      transform: scale(1);
      opacity: 1;

      //Move ranges list to the right
      .ranges {
        order: 10;
      }
    }
  }
</style>

